import React from 'react';
import './Main.css';
import './Header.css';
import { Link } from 'react-router-dom';
import HeaderOnePage from './HeaderOnePage';
import homepic from '../assets/HomeBackground.jpg'
import homepicR from '../assets/pic2.jpeg'
import BottomBar from './BottomBar';
import referred from '../assets/paint-01-01.png'
import feepic from '../assets/feee.png'


const HomeOnePage = () => {

let icono1 = homepic;
    const widthScreen = window.innerWidth > 790;


if (widthScreen) {
     icono1 = homepic
    } else {
     icono1 = homepicR
  }

  return (
    <div className="home-page" style={{height:'100vh'}}>
    <HeaderOnePage />

    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', height: '100vh' }}>
  {/* Coloca la imagen icono1 aquí */}
    <img src={icono1} alt="Icono 1" style={{ width: '100%', marginTop: widthScreen ? 50 : -100,loading:"lazy" }} />
    {/* Coloca el botón "GET A QUOTE" a la derecha, abajo de icono1 */}
    <div style={{ fontFamily: "Rockwell-Bold", width: '100%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
        {/* Título */}
        <p style={{ fontSize: widthScreen ? '2.5em' : '1.2em', fontWeight: 'bold', margin: 0 }}>
        PAINTERS GO WHERE YOU NEED THEM
        </p>
        <p style={{ fontSize: widthScreen ? '2.5em' : '1.2em', fontWeight: 'bold', margin: 0, marginBottom: 40 }}>
        TO GET THE JOB DONE!
        </p>
            <div style={{}}>
            <Link to="/contact" className="button-common" style={{ width: 200, height: 60, backgroundColor: '#000', fontSize: widthScreen ? 20 : 18, padding: 20 }}>
                GET A QUOTE
            </Link>
            </div>
              </div>

</div>


{/*
      <div style={{ marginLeft: 30 }}>
        <p className="fade-in" style={{ fontSize: widthScreen ? '2em' : '2em' }}>PAINTER is ready to GO..</p>
        <svg width="400" height="10" xmlns="http://www.w3.org/2000/svg">
          <line x1="0" y1="0" x2="200" y2="0" stroke="orange" strokeWidth="15">
            <animate attributeName="x2" from="0" to="300" dur="4s" fill="freeze" repeatCount="indefinite" />
            </line>
        </svg>
      </div>*/}


      <div style={{ display: 'flex', flexDirection: widthScreen ? 'row' : 'column', justifyContent: 'space-between', alignItems: 'center', marginTop:widthScreen? '-5%':'' }}>
        <div>
          <img src={feepic} alt="Imagen Fee" style={{ width: widthScreen ? '70%' : '90%', paddingLeft: widthScreen ? '25%' : '3%', loading: "lazy" }} />
        </div>
        <img src={referred} alt="Imagen Referred" style={{ width: widthScreen ? '350px' : '90%', zIndex: 99, marginLeft: widthScreen ? '0%' : 'auto', loading: "lazy" }} />
      </div>


<div style={{ flex: 1, minHeight: '100vh', display: 'flex', flexDirection:  'column', marginLeft: 5, marginRight: 10 }}>

        <div style={{ flex: 1, width: '100%', display: 'flex', flexDirection: widthScreen ? 'row' : 'column' , marginLeft:5, marginRight:10}}>
          {/* Primera columna */}
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
            <p style={{ fontSize: '2.3em', opacity: '50%', fontWeight: 'bold',marginLeft:20  }}>PAINTERGO’S PURPOSE</p>
            <p style={{ fontSize: '1.3em', marginLeft: 20, marginRight: 30, textAlign: 'left' }}>To provide painting services to the Miami-Dade
                        and Broward region with excellence.
            </p>

            <p style={{ fontSize:'1.3em', marginLeft: 20, marginRight: 30, textAlign: 'left' }}>
                        Our commitment lies in delivering skilled
                        professionals who are attentive to the unique
                        needs and concerns of each customer.
            </p>
             <p style={{ fontSize:'1.3em', marginLeft: 20, marginRight: 30, textAlign: 'left' }}>
                        Our mission is to uphold the utmost standard of
                        painting and related services, achieved through
                        professionalism, integrity, extensive experience,
                          and a team of highly skilled staff members.
            </p>
            <p style={{ fontSize:'1.3em', marginLeft: 20, marginRight: 30, textAlign: 'left' }}>
                        We take great pride in our capacity to effectively
                        address customer concerns, offer expert
                        guidance, and execute our work with unwavering
                        dedication to top-notch quality.
            </p>

          </div>
          {/* Segunda columna */}
          <div style={{marginBottom:100, flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'left' , marginLeft:5, marginRight:10}}>
            <p style={{ fontSize: '2.3em', opacity: '50%', fontWeight: 'bold' , textAlign:'left',marginLeft:20 }}>WHAT WE OFFER</p>
            <p style={{fontSize: '1.3em',marginLeft:20 ,marginRight:20,textAlign:'left'}}>
                ~ Clear and transparent expectations, highly trained applicators, and premium quality products.
            </p>
            <p style={{fontSize: '1.3em',marginLeft:20 ,marginRight:20,textAlign:'left'}}>
                ~ Ethical business practices, active community
                engagement, environmentally conscious operations,
                and the establishment of enduring and meaningful
                partnerships.
            </p>

         <p style={{ fontSize: '2.3em', opacity: '50%', fontWeight: 'bold' , textAlign:'left',marginLeft:20 }}>QUESTIONS?</p>
            <span style={{fontSize: '1.3em',textAlign:'left',marginLeft:20 }}>
            Contact us to schedule a consultation and discuss your
            specific requirements @ <strong>(305) 707-8977</strong> or
            <a style={{marginLeft:10, fontWeight:'bold', color:'#000', cursor:'ponter'}} href="https://mail.google.com/mail/?view=cm&to=info@paintergo.com">info@paintergo.com</a>.
            </span>
          </div>
        </div>

        {/* Orange Line */}

</div>
{/*
      <BottomBar widthScreen={widthScreen} />*/}

       <BottomBar />
    </div>
  );
};

export default HomeOnePage;
