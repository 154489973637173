import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import './Header.css';

let typeLog = "LOG OUT"
const Header = ({ showCircleButton, showTitle }) => {
  const menuRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(null);
  const widthScreen = window.innerWidth > 790;
  const toggleMobileMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavItemClick = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        setIsMenuOpen(false);
      }
    };

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleEscKey);
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <header style={{position:'fixed', zIndex:1000}} >
        <div className="header">
            <div style={{ left: 30 }} className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
            {widthScreen ?
                <>
                <div style={{ fontSize: '1.3em' , fontWeight:'bold'}}>MENU</div>

                </>
                  :
                <>
                <div className="hamburger-line"></div>
                <div className="hamburger-line"></div>
                <div className="hamburger-line"></div>
             </>
             }

        </div>
            <div className="logo-container" style={{ alignItems: 'center' }}>
             <Link to="/">
                    <img src={logo} alt="Logo" style={{ maxHeight: 60, marginTop:'2%' }} />
            </Link>
            </div>
                {widthScreen && (
            <div style={{backgroundColor:'#04BF55' ,  display: 'flex', // Para centrar verticalmente el contenido
                          borderRadius: 8, height: 40,
                          width: 120, fontWeight: 'bold', marginTop: 10,
                          justifyContent: 'center', alignContent: 'center',
                          alignItems: 'center', textAlign: 'center',
                          position: 'absolute', color: '#fff', fontSize: '1.3em', right: 30
                      }}>
                <Link
                    style={{ textDecoration: 'none' }}
                    to="/"
                    onClick={() => handleNavItemClick("/")}
                    className={currentPage === "/" ? "label-header-selected" : "label-header-not-selected"}
                        >
                    {typeLog}
                </Link>

            </div>
          )}
          {/* Icono de hamburguesa para dispositivos móviles */}

          <div className={`nav ${isMenuOpen ? 'open' : ''}`}  style={{width: widthScreen? '20%':'60%'}}>
            <ul className="nav-list">
              <li className="nav-item">
                <Link
                  to="/"
                  onClick={() => handleNavItemClick("/")}
                  className={currentPage === "/" ? "label-header-selected" : "label-header-not-selected"}
                >
                  HOME
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/residentialservice"
                  onClick={() => handleNavItemClick("/residentialservice")}
                  className={currentPage === "/residentialservice" ? "label-header-selected" : "label-header-not-selected"}
                >
                  RESIDENTIAL SERVICE
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/about"
                  onClick={() => handleNavItemClick("/about")}
                  className={currentPage === "/about" ? "label-header-selected" : "label-header-not-selected"}
                >
                  ABOUT
                </Link>
              </li>
              {/*<li className="nav-item">
                <Link
                  to="/gallery"
                  onClick={() => handleNavItemClick("/gallery")}
                  className={currentPage === "/gallery" ? "label-header-selected" : "label-header-not-selected"}
                >
                  GALLERY
                </Link>
              </li>*/}
              <li className="nav-item">
                <Link
                  to="/contact"
                  onClick={() => handleNavItemClick("/contact")}
                  className={currentPage === "/contact" ? "label-header-selected" : "label-header-not-selected"}
                >
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div>
        {showTitle && (
          <p style={{ zIndex: 200, alignItems: 'center', textAlign: 'center', color: '#000' }}>{showTitle}</p>
        )}
      </div>
    </div>
  );
}

export default Header;
