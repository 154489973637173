import React from 'react';
import './Main.css';
import './Header.css';
import HeaderOnePage from './HeaderOnePage';

// Importa tus imágenes
import image1 from '../assets/Satisfaction-800x800.jpg'
import image2 from '../assets/HighQuality-800x800.jpg'
import image3 from '../assets/Reliability-800x800.jpg'
import feepic from '../assets/feee.png'
import BottomBar from './BottomBar';

const About = () => {
  const widthScreen = window.innerWidth > 790;

  return (
    <div className="home-page">
      <HeaderOnePage />
      {/* Título */}
      <div className="residential-content">
      <div style={{  fontSize: '2em', opacity: '50%', fontWeight: 'bold', marginTop: 100, textAlign:'left' , paddingLeft:30}}>ABOUT US</div>
      </div>

      {/* Contenedor de las columnas */}
      <div
        className="residential-container"
        style={{
          display: 'flex',
          flexDirection: widthScreen ? 'row' : 'column', // Cambia a 'column' si el ancho de la pantalla es menor o igual a 790px
          alignItems: 'center', // Centra horizontalmente el contenido
          textAlign: 'center',
          margin: '10px auto', // Centra el contenedor verticalmente
          width: '100vw'
  // Ajusta el ancho del contenedor principal
        }}
      >
        {/* Primera columna */}
        <div className="residential-column" style={{padding:widthScreen? 20:10, width: widthScreen ? '33.33%' : '100%',  position: 'relative' }}>
        <div className="image-container">
            <div className="black-boxC">
            <strong style={{fontSize: '1.3em', fontFamily: 'Rockwell-Bold'}}>RELIABILITY</strong>
            </div>
            <img src={image3} alt="Imagen 1" style={{ maxWidth: '100%', loading: "lazy" }} />
            <p style={{ fontSize: '1.4em', textAlign: 'left',marginBottom:widthScreen? 0:'' }}>
                 Our unwavering commitment revolves around reliability. Customer satisfaction is our number one priority. Our services cater to your residential painting requirements, whether a house or an apartment. You can trust <strong>PAINTERGO</strong> to deliver exceptional results.
            </p>
        </div>

        </div>

    {/* Segunda columna */}
        <div className="residential-column" style={{ padding:widthScreen? 20:1, width: widthScreen ? '33.33%' : '100%',  position: 'relative' ,marginTop:widthScreen? '':'-30%'  }}>
        <div className="image-container">
            <div className="black-boxC">
            <strong style={{ fontSize: '1.3em', fontFamily: 'Rockwell-Bold' }}>HIGH QUALITY</strong>
            </div>
        <img src={image2} alt="Imagen 1" style={{ maxWidth: '100%', loading: "lazy" }} />
        <p style={{ fontSize: '1.4em', textAlign: 'left', marginBottom:widthScreen? 0:'' }}>
            Our mission at <strong>PAINTERGO</strong> is to offer
                forthright and dependable
                residential painting solutions that set
                us apart from the competition. We
                work with the industry's best products
                with meticulous attention to detail to
                surpass your expectations.
            </p>
        </div>

        </div>

  <div className="residential-column" style={{padding:widthScreen? 20:1, width: widthScreen ? '33.33%' : '100%', position: 'relative', marginTop:widthScreen? '':'-30%' }}>
        <div className="image-container">
            <div className="black-boxC" style={{padding:'5px'}}>
            <strong style={{ fontSize: '1.3em', fontFamily: 'Rockwell-Bold' }}>SATISFACTION</strong>
            </div>
            <img src={image1} alt="Imagen 1" style={{ maxWidth: '100%', loading: "lazy" }} />
           <p style={{ fontSize: '1.4em', textAlign: 'left' , marginBottom:widthScreen? 0:''}}>
                  We adapt to your schedule and engage in comprehensive consultations to delve into every facet of color selection and coordination for your project, ensuring that the final result aligns perfectly with your preferences. Your satisfaction is not just our goal; it's <strong>PAINTERGO's</strong> happiness.
            </p>
        </div>

    </div>


      </div>

      {/* Imagen de referencia */}
          <img src={feepic} style={{ width: widthScreen ? '35%' : '90%', alignSelf: 'center', marginLeft: widthScreen ? 30 : 0, marginTop:widthScreen? '':'-20%'  }} alt="Imagen de referencia" />
          <BottomBar />
    </div>
  );
};

export default About;
