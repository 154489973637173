import React, { useState } from 'react';
import axios from 'axios';

// eslint-disable-next-line
import Header from './Header';
import { colors } from "../utils/index";
import './Main.css';
const Registration = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const widthScreen = window.innerWidth < 790;
  const handleRegister = () => {
    // Basic email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert('Invalid Email. Please enter a valid email address.');
      return;
    }

    // Basic password validation (minimum length)
    if (password.length < 6) {
      alert('Invalid Password. Password must be at least 6 characters.');
      return;
    }

    // Define the request body
    const body = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone1: phone1,
      phone2: phone2,
    };

    // Define the axios configuration
    const config = {
      method: 'post',
      url: 'https://e0gvj8wcd6.execute-api.us-east-1.amazonaws.com/prod/registerfluiw', // Replace with your registration endpoint
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      data: body,
    };

    // Send the registration request
    axios(config)
      .then((response) => {
        if (!response.data.error) {
          alert('Registration Successful. You have successfully registered.');
          // You can navigate to a login screen or perform other actions here
            localStorage.clear();
             window.location.href = "/";
        } else {
          alert( response.data.error);
        }
      })
      .catch((error) => {
        alert( error.message);
      });
  };
 const onClose = () => {
 localStorage.clear();
    window.location.href = "/login";
}
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: widthScreen?'flex-start': 'center',
    marginTop: 5,
    paddingLeft: 20,
    width:'90vw'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    width: widthScreen? '100%': '60%',
    marginBottom: 10,
  },
  label: {
    flex: '20%', // Ocupa el 20% del ancho
    fontSize: '0.9em',
    color: '#fff',
    marginLeft: 5,
    marginRight: 10,
    textAlign:'left'

  },
  input: {
    flex: '80%', // Ocupa el 80% del ancho
    marginBottom: 10,
    height: 35,
    fontSize: '0.9em',
    borderRadius: 15,
    paddingLeft: 10,
    paddingRight: 30,
    textTransform: 'uppercase',
    border:'none'
  },
  button: {
    marginTop: 35,
    height: 35,
    fontSize: '1.1em',
    border: 'none',
    width: 200,
    color: colors.WHITE,
    backgroundColor: colors.DARKGREEN,
    borderRadius: 15,
    marginRight: 10,
    cursor:'ponter'
  },
  backButton: {
    marginTop: 35,
    height: 35,
    fontSize: '1.1em',
    width: 200,
    border: 'none',
    color: colors.WHITE,
    backgroundColor: colors.DARKGREEN,
    borderRadius: 15,
    cursor:'ponter'
  },
};


  return (
     <div className="home-page" style={{marginBottom:0}}>
         <Header showTitle="" colorTop="false"/>

       <div style={{ backgroundColor: colors.ORANGE, backgroundSize: 'cover', height:widthScreen? '85vh': '75vh', borderRadius: 15, width:widthScreen?'95%': '60%', display: 'flex', flexDirection: 'column', alignItems: 'center',alignSelf:'center', justifyContent: 'center', marginTop:100, padding:5 }}>
        <header style={{ textAlign: 'center' }}>
          <p className="titleDetail" style={{ color: '#fff', fontSize: '1.9em', marginTop: '1%' }}>REGISTER</p>
        </header>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={styles.container}>
            <div style={styles.row}>
              <label style={styles.label}>Email:</label>
              <input style={styles.input}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
            </div>
            <div style={styles.row}>
              <label style={styles.label}>Password:</label>
             <input style={styles.input}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
              />
            </div>
            <div style={styles.row}>
              <label style={styles.label}>First Name:</label>
              <input style={styles.input}
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter your first name"
              />
            </div>
            <div style={styles.row}>
              <label style={styles.label}>Last Name:</label>
               <input style={styles.input}
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter your last name"
              />
            </div>
            <div style={styles.row}>
              <label style={styles.label}>Phone 1:</label>
               <input style={styles.input}
                type="text"
                value={phone1}
                onChange={(e) => setPhone1(e.target.value)}
                placeholder="Enter your phone number"
              />
            </div>
            <div style={styles.row}>
              <label style={styles.label}>Phone 2:</label>
               <input style={styles.input}
                type="text"
                value={phone2}
                onChange={(e) => setPhone2(e.target.value)}
                placeholder="Enter your alternate phone number"
              />
        </div>
    <div>
                <button onClick={handleRegister} style={styles.button}>Register</button>
                <button onClick={onClose} style={styles.backButton}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Registration;