// Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import icono1 from '../assets/icono1.png';
import icono2 from '../assets/icono1.png'; // Asegúrate de importar las imágenes correctas
import icono3 from '../assets/icono1.png'; // Asegúrate de importar las imágenes correctas
import './Main.css';
import './Header.css';
import Header from './Header'

const Home = (user) => {

    //const [menuOpen, setMenuOpen] = useState(false);

    //const widthScreen = useState(window.innerWidth < 790);
 if (localStorage.username === undefined ) {
        localStorage.clear();
        window.location.href = "/";
    }
    return (
        <div className="home-page">
            <Header showTitle="HOME" />
             <p style={{fontSize:20,fontWeight:'bold', alignItems: 'center', textAlign: 'center' , color:'green', marginTop:50}}>HOME</p>
            <div style={{ marginTop:20, paddingLeft:20,fontWeight:'bold'}}>Welcome, {localStorage.username}!</div>
            {/* Resto del contenido de Home */}
            {/* Contenedor de Iconos */}
            <div className="icon-container"  >

                {/* Icono 1 */}
                <Link to="/referredfriend"  style={{ textDecoration:'none'}}>
                    <div className="icon">
                        <img src={icono1} alt="Icono 1" style={{width:100}} /> {/* Usa la imagen importada */}
                        <p >Referred Friend</p>
                    </div>
                </Link>

                {/* Icono 2 */}
                <Link to="/rentalmanager"  style={{ textDecoration:'none'}}>
                    <div className="icon">
                        <img src={icono2} alt="Icono 1" style={{width:100}} /> {/* Usa la imagen importada */}
                        <p>Rental Manager</p>
                    </div>
                </Link>

                {/* Icono 3 */}
                <Link to="/homeowner"  style={{ textDecoration:'none'}}>
                    <div className="icon">
                        <img src={icono3} alt="Icono 1"  style={{width:100}}/> {/* Usa la imagen importada */}
                        <p>HomeOwner</p>
                    </div>
                </Link>
            </div>

            {/* Bottom Bar */}
            <div className="bottom-bar">
                {/* Icono 5 */}
                <Link to="/opcion5">
                    <i className="fas fa-icono5"></i>
                </Link>
            </div>
        </div>
    );
};

export default Home;
