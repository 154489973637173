import React from 'react';
import { Link } from 'react-router-dom';

const BottomBar = ({ widthScreen }) => {
    const widthScreenB = window.innerWidth > 790;
  return (
      <div className="bottom-bar">
        <div style={{ backgroundColor: 'grey', marginTop:widthScreenB? 20:-1 }}>
            <div style={{ display: 'flex', justifyContent: 'space-around',  flexDirection: widthScreenB? 'row':'row'}}>
                <Link to="/about" className="button-common" style={{ marginTop:5,fontFamily: "Rockwell-Bold", width:widthScreenB ?200:100, height:widthScreenB? 35:25,  fontSize: widthScreenB?  '1.3em':'0.9em' , display: 'flex', alignItems: 'center', justifyContent: 'center' }}>ABOUT</Link>
                <Link to="/residentialservice" className="button-common" style={{ marginTop:5,fontFamily: "Rockwell-Bold",  width:widthScreenB ?200:100, height:widthScreenB? 35:25,  fontSize: widthScreenB?  '1.3em':'0.9em' , display: 'flex', alignItems: 'center', justifyContent: 'center' }}>RESIDENTIAL SERVICES</Link>
                <Link to="/contact" className="button-common" style={{ marginTop:5,fontFamily: "Rockwell-Bold",  width:widthScreenB ?200:100, height:widthScreenB? 35:25,  fontSize: widthScreenB?  '1.3em':'0.9em' , display: 'flex', alignItems: 'center', justifyContent: 'center'}}>GET A QUOTE</Link>
              </div>
                <p style={{height:35,fontSize:'0.9em', marginLeft: 20, marginRight: 20, textAlign: 'center', color:'#fff', fontWeight:'bold', marginTop:5 }}>
                    © 2023 PAINTERGO. All rights reserved.
                </p>
            <div>

            </div>
        </div>
    </div>
  );
};

export default BottomBar;
