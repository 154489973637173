
import React from 'react';
import './Main.css';
import './Header.css';
import HeaderOnePage from './HeaderOnePage';
import icono1 from '../assets/pic3.jpg';
import BottomBar from './BottomBar';
const Gallery = () => {
  const widthScreen = window.innerWidth > 790;

  return (
    <div className="home-page">
      <HeaderOnePage />
      <p style={{ fontSize: 20, fontWeight: 'bold', alignItems: 'center', textAlign: 'center', color: 'green', marginTop: 50 }}></p>
      <div>


        <div style={{ flex: 1, width: '100%', display: 'flex', flexDirection: widthScreen ? 'row' : 'column', padding: 15 }}>
          {/* Primera columna */}
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p style={{ fontSize: 24, opacity: '50%', fontWeight: 'bold' }}>RESIDENTIAL SERVICES</p>
            <p style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft:20 }}>
              We pride ourselves in doing the job right the first time. All of our services include the proper preparation to ensure that your paint job will last for years to come!

                Some of our residential services include:
                Full interior repaints
                Full exterior repaints
                Cabinet and trim painting
                Wallpaper removal
                Drywall repair
                New construction painting
                Staining and finishing of wood surfaces
                Concrete sealers and other coatings
                Color consolations


                This is by no means an extensive list. Contact us today to discuss your needs!
            </p>
          </div>
          {/* Segunda columna */}
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',width:'100%' }}>
             <div>
            <img src={icono1} alt="Icono 1" style={{ width: '100%'}} />
            </div>
          </div>
        </div>

        {/* Orange Line */}


      </div>
      <BottomBar widthScreen={widthScreen} />

    </div>
  );
};

export default Gallery;
