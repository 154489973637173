import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './Pages/Login';
import Home from './Pages/Home';
import HomeCover from './Pages/HomeCover';
import HomeOwner from './Pages/HomeOwner';
import RentalManager from './Pages/RentalManager';
import Registration from './Pages/Registration';
import ReferredFriend from './Pages/ReferredFriend';
import HomeOnePage from './Pages/HomeOnePage';
import About from './Pages/About';
import Gallery from './Pages/Gallery';
import Contact from './Pages/Contact';
import HeaderOnePage from './Pages/HeaderOnePage';
import ResidentialService from './Pages/ResidentialService';

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/" exact component={HomeOnePage} />
          <Route path="/home" exact component={Home} />
          <Route path="/homecover" exact component={HomeCover} />
          <Route path="/rentalmanager" exact component={RentalManager} />
          <Route path="/referredfriend" exact component={ReferredFriend} />
          <Route path="/homeowner" exact component={HomeOwner} />
          <Route path="/registration" exact component={Registration} />
          <Route path="/login" exact component={Login} />
          <Route path="/about" exact component={About} />
          <Route path="/gallery" exact component={Gallery} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/headeronepage" exact component={HeaderOnePage} />
          <Route path="/homeonepage" exact component={HomeOnePage} />
          <Route path="/residentialservice" exact component={ResidentialService} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
