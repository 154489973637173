import React, { useState , useEffect} from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line
import logo from '../assets/logo.png';
// eslint-disable-next-line

import { colors } from "../utils/index";
import './Main.css';
// eslint-disable-next-line
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider,signOut } from 'firebase/auth';
import { app } from '../firebase';
// eslint-disable-next-line
import { OAuthProvider } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import Loader from "react-loader-spinner";
import Header from './Header'
const auth = getAuth(app);
// eslint-disable-next-line
const analytics = getAnalytics(app);

const Login = () => {

    const [user, setUser] = useState(null);
    const [email, setEmail] = useState('');

    //const [username, setUsername] = useState('');

    const [userTypeID, setuserTypeID] = useState('');
    const [userTypeName, setuserTypeName] = useState('');
    const [password, setPassword] = useState('');
    const [showForgotPassModal, setShowForgotPassModal] = useState(false);
    const [popupOpenError, setPopupOpenError] = useState(false);
    const [mensaje, setMensaje] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const widthScreen = (window.innerWidth < 790)
    const history = useHistory();
    const [showLogin, setShowLogin] = useState(false);

  const handleUserButtonClick = () => {
      setShowLogin(true);
      setuserTypeID('1')
      setuserTypeName('USER')// Mostrar la sección de inicio de sesión cuando se presiona el botón "USER"
  };

  const handlePainterButtonClick = () => {
      setShowLogin(true);
      setuserTypeID('2')
      setuserTypeName('PAINTER')// Mostrar la sección de inicio de sesión cuando se presiona el botón "PAINTER"
  };

 const handleToggleRegistration = () => {
    history.push({
                pathname: '/registration'
            });
  };
// eslint-disable-next-line
const guardarDatosUsuario = async (user) => {

};

const handlePopupCloseError = () => {
    //setPayEliminarNo(false)
    setPopupOpenError(false);
    setIsLoading(false);
     };


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
          setUser(authUser);
          setPassword(authUser.emailVerified)
          setEmail(authUser.email)
      } else {
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
        await signInWithPopup(auth, provider);

        await signOut(auth);
    } catch (error) {
      console.error(error);
    }
  };
  const handleForgotPass = () => {
    setShowForgotPassModal(true);
  };

  const handleCloseModal = () => {
    setShowForgotPassModal(false);
  };


  const handleSubmit = (event) => {
    // Aquí puedes implementar la lógica para enviar un correo con el enlace para restablecer la contraseña.
    // Esto puede implicar una llamada a una API o servicio de correo electrónico.
      setEmail(event.target.value);
    // Después de enviar el correo, puedes cerrar el modal.
    handleCloseModal();
  };
//  const handleFacebookLogin = async () => {
//    const provider = new FacebookAuthProvider();
//    try {
//        await signInWithPopup(auth, provider);
//        const user = auth.currentUser;

//        console.log('acac user google', user)
//    } catch (error) {
//      console.error(error);
//    }
//  };

//  const handleAppleLogin = async () => {
//    const provider = new OAuthProvider('apple.com');
//    try {
//      await signInWithPopup(auth, provider);
//    } catch (error) {
//      console.error(error);
//    }
//  };


// eslint-disable-next-line
  const handleemailChange = (event) => {
    setEmail(event.target.value);
  };
// eslint-disable-next-line
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

 const handleLogin = async () => {
     if (( email === '' && password === '' ) || (password === true &&  email === '')) {
        return
     }
setIsLoading(true)
    const requestBody = {
      email: email,
      password: password
    };
//console.log('acac body login', requestBody)
    fetch('https://e0gvj8wcd6.execute-api.us-east-1.amazonaws.com/prod/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain'
      },
      body: JSON.stringify(requestBody)
    })
    .then(response => response.json())
        .then(data => {

      if (data.error) {
          setMensaje('ERROR INICIO DE SESION')
          setPopupOpenError(true)
          setIsLoading(false)
      } else {
          setIsLoading(false)
          if (data.first_name !== '') {
                localStorage.setItem('email', data.email);
                localStorage.setItem('username', data.first_name);
          } else {
              localStorage.setItem('email', user.email);
              localStorage.setItem('username', user.displayName);
              console.log(userTypeID)
          }

            history.push({
                pathname: '/home',
                state: { user:user}
            });
        // Manejar la respuesta de la API aquí
      }
    })
    .catch(error => {
      // Manejar errores de la API aquí
      console.error(error);
    });
  };

    const buttonStyle = {
    marginBottom: 25,
    height: 25,
    fontSize:  '1.3em',
    border:'none',
    width: 150,
    color:  'white',
    backgroundColor:colors.BLACK,
    borderRadius: 15,
    transition: 'background-color 0.3s ease',
    cursor:'pointer'
    };

  return (
        <div className="home-page" style={{marginBottom:0}}>
         <Header showTitle="" colorTop="false"/>
      <div style={{ backgroundColor: '#fff', backgroundSize: 'cover', height: '100%' }}>
        <header style={{ textAlign: 'center' }}>
          {/*<img src={logo} alt="Logo" style={{ marginTop: '10%' , width:widthScreen?350: '30%'}} />*/}

              </header>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {isLoading && <Loader type="TailSpin" color="#fff" height={80} width={80}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  position:'absolute', marginTop:'50%'}} />}
                  <>
                      {/* Botones "USER" y "PAINTER" */}
                      {!showLogin && (
                          <>

                          <div style={{ display: 'flex', flexDirection: widthScreen?'column': 'row', justifyContent: 'center', marginTop:widthScreen?'50%':'25%' }}>
                                  <button className="button-common" style={{ width: 250, marginRight: 20, height: 70 }} onClick={handleUserButtonClick}>
                                  USER
                              </button>
                              <button className="button-common" style={{ width: 250, height: 70, marginTop: widthScreen? 50:''}} onClick={handlePainterButtonClick}>
                                  PAINTER
                              </button>
                              </div>
                            </>
             )}

       {/* Sección de inicio de sesión */}
          {showLogin && (
        <>
        <div
                        style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // Establece el ancho máximo o el ancho según tus preferencias.
                        maxWidth: '400px' // Cambia el valor según tus necesidades.

                        }}
  ></div>
        <div style={{width:widthScreen?'90%': '60%', maxHeight:'100vh', borderRadius:15, backgroundColor:colors.DARKGREEN, marginTop:widthScreen? '40%': '8%' , bottom:0 }}>
            <div style={{ display: 'flex',padding:widthScreen?'15%':'1%', flexDirection: 'column', alignItems: 'center', marginTop: '2%', height:widthScreen?'50vh':'65vh'}}>
                <h1 style={{color: '#fff'}} >LOGIN { userTypeName}</h1>
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                  <span className="titleDetail">USER(e-mail)</span>
                  <input
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{  fontSize:widthScreen?'1em':'1.4em',marginBottom: '3%', padding: '3%', width: 300, borderRadius: 15, border: 'none' }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                  <span className="titleDetail">PASSWORD</span>
                  <input
                    type="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ fontSize:widthScreen?'1em':'1.4em', marginBottom: '3%', padding: '3%', width: 300, borderRadius: 15, border: 'none' }}
                  />
                </div>
                <div style={{ textAlign: 'left', marginTop: '1%', fontSize: '1em', marginBottom: '1%' }}>
                  <span
                    style={{
                      color: 'white',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      fontStyle: 'italic'
                    }}
                    onClick={handleForgotPass}
                  >
                    Forgot Password
                  </span>
                </div>
          <button onClick={handleLogin} className="button-common" style={{ width: 200  , marginTop:widthScreen?'5%':'2%',}}>
                SIGN IN
            </button>
            <button onClick={handleToggleRegistration} style={{
                        marginBottom: '2%',
                        marginTop:widthScreen?'5%':'2%',
                        height:widthScreen? 25:35,
                        border:'none',
                        width: widthScreen?200:300,
                        color:  'white',
                        backgroundColor:colors.BLACK,
                        borderRadius: 15,
                        cursor: 'pointer',
                         fontSize:widthScreen?'1em':'1.4em',
                        transition: 'background-color 0.3s ease',}} >
                    I'm New to PainterGo
              </button>

                <button style={{
                        marginBottom: '2%',
                         marginTop:widthScreen?'5%':'2%',
                        height: 35,
                        border:'none',
                        width: 250,
                        color:  '#000',
                        backgroundColor:'#fff',
                        borderRadius: 15,
                        cursor: 'pointer',
                        fontSize:widthScreen?'1em':'1.4em',
                        transition: 'background-color 0.3s ease',}}
                 onClick={handleGoogleLogin}>Iniciar sesión con Google</button>
              </div>

    </div>
            </>
          )}

                    {/* Modal para restablecer la contraseña */}
              {showForgotPassModal && (
                <div
                  className="modal"
                  style={{
                    backgroundColor: colors.ORANGE, // Fondo blanco para el modal
                    position: 'absolute', // Posición absoluta para superponer el modal
                    top:'25%', // Alineado en la parte superior
                    width:widthScreen?'90%': '50%', // Ancho de pantalla completo
                    height: '50%', // Altura de pantalla completa
                    display: 'flex',
                    borderRadius: 10,
                    flexDirection:'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding:10
                  }}
                    >
                            <span className="close" style={{cursor:'pointer'}} onClick={handleCloseModal}>
                            X
                            </span>
                            <h2>Set Your Password</h2>
                            <p>
                            Please enter your email address and we will send you an email with a link to
                            set your password.
                            </p>
                            <input
                                    type="text"
                                    placeholder="email@gmail.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{ marginBottom: '10px', padding: '8px', width: 300, borderRadius: 15 }}
                                />
                            <button  className="button-common" onClick={handleSubmit}>Submit</button>
                        </div>


                    )}

                    {popupOpenError && (
                    <div style={{ position: 'absolute', top: 0,  width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{width:'80%', backgroundColor: 'red', padding: '20px', borderRadius:10, maxWidth: '300px', height:250 }}>
                        <p style={{marginTop:'20%', alignSelf:'center', justifyContent:'center', alignItems:'center', textAlign:'center', color:'white',  fontSize:  '1em', fontWeight:'bold'}}>{mensaje}</p>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}>
                            <div>
                                <button style={{
                                        ...buttonStyle,
                                        backgroundColor: colors.BLACK,
                                        color: colors.DARKGREEN
                                    }}
                                    onClick={handlePopupCloseError}
                                    >
                                    OK
                                </button>
                                </div>
                        </div>
                    </div>

                    </div>
                )}


                  {/*<button onClick={handleFacebookLogin}>Iniciar sesión con Facebook</button>
                  <button onClick={handleAppleLogin}>Iniciar sesión con Apple</button>*/}
            </>

        </div>
      </div>

    </div>
  );
};

export default Login;