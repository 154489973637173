import React from 'react';
import './Main.css';
import './Header.css';
import Header from './Header';

const HomeCover = () => {
  return (
    <div className="home-page">
      <Header showTitle="" />
      <p style={{ fontSize: 20, fontWeight: 'bold', alignItems: 'center', textAlign: 'center', color: 'green', marginTop: 50 }}></p>
      <div className="icon-container">
        <div className="animation-container">
          <p className="fade-in">PAINTER is ready to GO..</p>
          <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
            <line x1="0" y1="0" x2="200" y2="0" stroke="orange" strokeWidth="15">
              <animate attributeName="x2" from="0" to="400" dur="4s" fill="freeze" repeatCount="indefinite" />
            </line>
          </svg>
        </div>
      </div>
      <div className="bottom-bar"></div>
    </div>
  );
};

export default HomeCover;
