import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAwts23w-1SdqrnNTdXKCb8XugcCec4XGM",
  authDomain: "cgs-web-c457d.firebaseapp.com",
  projectId: "cgs-web-c457d",
  storageBucket: "cgs-web-c457d.appspot.com",
  messagingSenderId: "164400914638",
  appId: "1:164400914638:web:088903952dfb1a29e3709f"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
