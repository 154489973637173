import React from 'react';
import './Main.css';
import './Header.css';
import HeaderOnePage from './HeaderOnePage';
import pic1 from '../assets/resdidentialServices/firstimg.jpg';
import pic2 from '../assets/resdidentialServices/apart.jpeg';
import feepic from '../assets/feee.png';
import BottomBar from './BottomBar';
const ResidentialService = () => {
  const widthScreen = window.innerWidth > 790;

  return (
    <div className="home-page">
      <HeaderOnePage />
    <div style={{}}>

        <div style={{ fontSize: '2em', opacity: '50%', fontWeight: 'bold', marginTop: 100, textAlign: 'left', paddingLeft: 30 }}>RESIDENTIAL SERVICES</div>
        <div style={{ display: widthScreen ? 'flex' : 'block', justifyContent: 'center', alignItems: 'center', margin: '30px' }}>
            {/* Primera columna */}
            <div style={{ flex: '1', maxWidth: widthScreen ? 'calc(50vw - 30px)' : '90vw', position: 'relative', marginRight: widthScreen ? '30px' : 0, marginBottom: widthScreen ? '' : '40px' }}>
            <div style={{ fontSize: '1.3em', fontFamily: 'Rockwell-Bold', position: 'absolute', top: -20, left: -30, background: 'black', color: 'white', padding: '10px', textAlign: 'center', width:widthScreen? '35%': '45%' }}>HOUSES</div>
            <img src={pic2} alt="Icono 1" style={{ width: '100%', border: '2px solid black' }} />
            </div>

            {/* Segunda columna */}
            <div style={{ flex: '1', maxWidth: widthScreen ? 'calc(50vw - 30px)' : '90vw', position: 'relative', marginLeft: widthScreen ? '30px' : 0 }}>
            <div  style={{ fontSize: '1.3em', fontFamily: 'Rockwell-Bold', position: 'absolute', top: -20, right: -30, background: 'black', color: 'white', padding: '10px', textAlign: 'center', width:widthScreen? '35%':'45%' }}>APARTMENTS</div>
            <img src={pic1} alt="Icono 2" style={{ width: '100%', border: '2px solid black' }} />
            </div>
        </div>


      {/* Agregar el contenido debajo de las imágenes */}
      <div style={{ marginRight: '30px', marginLeft:'30px', textAlign: 'left', fontSize:'1.3em'}}>
        <p>
          Our goal is to get the job done correctly from the very start, whether it is a house or an apartment. Each of our services
          is executed with meticulous preparation to guarantee the longevity of your paintwork. Our array of residential offerings
          encompasses:
        </p>
      </div>

      {/* Columnas para mostrar servicios */}
      <div style={{ display: 'flex', flexDirection: widthScreen ? 'row' : 'column', justifyContent: 'center', alignItems: 'center',  width:'100%' }}>
        {/* Primera columna */}
        <div style={{ justifyContent: widthScreen ? 'flex-start' : 'flex-start', alignSelf: widthScreen ? 'flex-start' : 'flex-start', textAlign: 'left', width: '60%', paddingLeft :widthScreen?30 :'', fontSize:'1.3em'}}>
          <ul>
            <li>Complete interior repainting</li>
            <li>Full-scale exterior repainting</li>
            <li>Painting for cabinets and trim</li>
            <li>Wallcover and wallpaper removal</li>
            <li>Proficient drywall repairs</li>
            <li>Painting houses and apartments only</li>
          </ul>
        </div>
        {/* Segunda columna */}
        <div>
          <img src={feepic} style={{ width: widthScreen ? '70%' : '95%', alignSelf:widthScreen? 'right':'center', padding: widthScreen ? 30 : 10 }} alt="Imagen de referencia" />
        </div>
      </div>

      <div style={{ textAlign: 'left', paddingLeft: 20, paddingRight:10, fontSize:'1.3em' , marginBottom:30}}>
        <span>
          These are just a few services. If you wish to discuss your unique requirements, please Contact Us @ <strong>(305) 707-8977 or</strong>
        </span>
        <span>
         <a style={{fontWeight:'bold', color:'#000', cursor:'ponter'}} href="https://mail.google.com/mail/?view=cm&to=info@paintergo.com"> info@paintergo.com</a>.
        </span>
      </div>
          </div>
          <BottomBar />
  </div>
  );
};

export default ResidentialService;