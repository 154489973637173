import React, { useState } from 'react';

import axios from 'axios';
import './Header.css';
import HeaderOnePage from './HeaderOnePage';
import Loader from "react-loader-spinner";
import backgroundImage from '../assets/pic-01.png'
import { colors } from "../utils/index";
import './Main.css';
import BottomBar from './BottomBar';

const Contact = () => {
  const [email, setEmail] = useState('');

//  const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone1, setPhone1] = useState('');
    const [comment, setComment] = useState('');
    const [popupOpenError, setPopupOpenError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [colorerror, setColorerror] = useState('false');
    const [mensaje, setMensaje] = useState('');
    const widthScreen = window.innerWidth > 790;



const handlePopupCloseError = () => {
    //setPayEliminarNo(false)
    setPopupOpenError(false);
    setColorerror(false);
    setIsLoading(false);
     };
const handleRegister = () => {
    // Basic email validation using a raegular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert('Invalid Email. Please enter a valid email address.');
      return;
    }


    //// Basic password validation (minimum length)
    //if (password.length < 6) {
    //  alert('Invalid Password. Password must be at least 6 characters.');
    //  return;
      //}

   // Define the request body
    const body = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone1: phone1,
      comment: comment,
    };
     setIsLoading(true);
    // Define the axios configuration
    const config = {
      method: 'POST',
      url: 'https://2xzvnx4ja2.execute-api.us-east-2.amazonaws.com/prod/senmail', // Replace with your registration endpoint
      headers: {
          'Content-Type': 'application/json'
      },
      data: JSON.stringify(body)   };

    // Send the registration request
    axios(config)
      .then((response) => {
        console.log(response.data)
        if (response.data.message) {
            setIsLoading(false);
            setColorerror(false)
            setMensaje('Registration Successful. You have successfully registered')
            setPopupOpenError(true);
          // You can navigate to a login screen or perform other actions here
            localStorage.clear();
             window.location.href = "/";
        } else {
            setIsLoading(false);
            setColorerror(true)
            setMensaje(response.data.error)
            setPopupOpenError(true);
        }
      })
      .catch((error) => {
        alert( error.message);
      });
  };

const styles = {
  container: {
   display: 'flex',
    flexDirection: 'column',
    borderRadius: 15,
    alignItems: 'center',
    padding: widthScreen ?'1%':0,
    width: widthScreen ? '60%' : '90%',
    height:'70vh',
    marginTop:widthScreen?'1em':'5em',
    backgroundColor: colors.DARKGREEN

  },
  row: {
    display: widthScreen?'flex':'block',
    alignItems:widthScreen? 'center':'flex-start',
    width: widthScreen? '100%': '90%',
    marginBottom: 5,
  },
  label: {
    flex: widthScreen?'20%':'1%', // Ocupa el 20% del ancho
    fontSize: '1.1em',
    color: '#fff',
    fontWeight:'bold',
    marginLeft: 5,
    marginRight: 10,
    textAlign:'left'

  },
  input: {
    flex: '100%', // Ocupa el 80% del ancho
    marginBottom: 10,
    height: 30,
    fontSize: '0.9em',
    borderRadius: 15,
    paddingLeft: 10,
    paddingRight: 30,
    textTransform: 'uppercase',
    border: 'none',
    width:'100%'
  },
  button: {
    height: '3em',
    fontSize: '1.1em',
    border: 'none',
    width: '12em',
    fontFamily:'Rockwell-Bold',
    color: colors.WHITE,
    backgroundColor: '#000',
    borderRadius: 15,
    marginRight: 10,
    cursor:'ponter',
  },

};


  return (
   <div className="home-page">
      <HeaderOnePage />
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh', // Make the background cover the entire viewport height
          display: 'flex',
          flexDirection: 'column', // Stack children vertically


        }}
      >
        {/* "Contact Us" text */}
        <div
          style={{
            fontSize: '2em',
            opacity: '50%',
            fontWeight: 'bold',
            marginTop:70,
            textAlign: 'left',
            paddingLeft: 30,

          }}
        >
          CONTACT US
        </div>

        {/* Green box content */}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: widthScreen ? '2%' : '-10%',
            marginBottom: '10px'

          }}
        >
          <div
          style={{
            flexDirection: 'column',
            width:widthScreen? '60%': '80%',
            padding: 50,
            borderRadius:15,
            display: 'flex',
            backgroundColor:'#04BF55',
            justifyContent: 'center',
            alignItems: 'center', // Center vertically
            marginTop: widthScreen ? '' : '10%',
            height: widthScreen? '55vh':'70vh', // Set a fixed height for the green container

          }}
        >

        <p className="titleDetail" style={{ marginTop: widthScreen ? '35px' : '10px', color: '#fff', fontSize: widthScreen ? '1.5em' : '1.3em', textAlign: 'center', width:'100%' }}>Have questions? Want to discuss your project?
              </p>
        <p className="titleDetail" style={{ marginTop: widthScreen ? '-10px' : '-10px', color: '#fff', fontSize: widthScreen ? '1.5em' : '1.3em', textAlign: 'center', width:'100%' }}>
               Call us at (305) 707-8977or submit an inquiry below!</p>
            <div style={styles.row}>
              <label style={styles.label}>Email:</label>
              <input style={styles.input}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
            </div>
            {/*<div style={styles.row}>
              <label style={styles.label}>Password:</label>
             <input style={styles.input}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
              />
            </div>*/}
            <div style={styles.row}>
              <label style={styles.label}>First Name:</label>
              <input style={styles.input}
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter your first name"
              />
            </div>
            <div style={styles.row}>
              <label style={styles.label}>Last Name:</label>
               <input style={styles.input}
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter your last name"
              />
            </div>
            <div style={styles.row}>
              <label style={styles.label}>Phone Number:</label>
               <input style={styles.input}
                type="number"
                value={phone1}
                onChange={(e) => setPhone1(e.target.value)}
                placeholder="Enter your phone number"
              />
            </div>
            <div style={styles.row}>
            <label style={styles.label}>Comment:</label>
            <textarea
                style={{ ...styles.input, fontFamily:'ITCAvantGardeGothicStdMedium', fontSize: '0.9em' , height:'5.5em'}}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Enter your comments"
            />
            </div>
            {/*<div style={styles.row}>
              <label style={styles.label}>Phone 2:</label>
               <input style={styles.input}
                type="text"
                value={phone2}
                onChange={(e) => setPhone2(e.target.value)}
                placeholder="Enter your alternate phone number"
              />
                      </div>*/}
             {isLoading && <Loader type="TailSpin" color="#fff" height={80} width={80}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  position:'absolute', marginTop:'50%'}} />}
            <div>
                <button onClick={handleRegister} style={styles.button}>SUBMIT</button>
            </div>
          </div>
        </div>
          </div>
            {popupOpenError && (
        <div style={{ position: 'absolute', top: 0,  width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div style={{width:'80%', backgroundColor:  colorerror?'red':  'green', padding: '20px', borderRadius:10, maxWidth: '300px', height:250 }}>
            <p style={{marginTop:'20%', alignSelf:'center', justifyContent:'center', alignItems:'center', textAlign:'center', color:'white',  fontSize:  '1.5em', fontWeight:'bold'}}>{mensaje}</p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}>
                <div>
                    <button style={{
                            ...styles.button,
                             backgroundColor: colors.BLACK,
                            color: '#fff'
                        }}
                        onClick={handlePopupCloseError}
                         >
                        OK
                    </button>
                    </div>
            </div>
         </div>

        </div>
          )}
          <BottomBar />
    </div>
  );
};


export default Contact;